// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-layout-item-page-jsx": () => import("./../../../src/layout/item-page.jsx" /* webpackChunkName: "component---src-layout-item-page-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-grocery-jsx": () => import("./../../../src/pages/grocery.jsx" /* webpackChunkName: "component---src-pages-grocery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-meat-jsx": () => import("./../../../src/pages/meat.jsx" /* webpackChunkName: "component---src-pages-meat-jsx" */),
  "component---src-pages-produce-jsx": () => import("./../../../src/pages/produce.jsx" /* webpackChunkName: "component---src-pages-produce-jsx" */),
  "component---src-pages-purchase-successful-jsx": () => import("./../../../src/pages/purchase-successful.jsx" /* webpackChunkName: "component---src-pages-purchase-successful-jsx" */),
  "component---src-pages-takeout-jsx": () => import("./../../../src/pages/takeout.jsx" /* webpackChunkName: "component---src-pages-takeout-jsx" */)
}

