/* User defined data
 *
 * Input user data here.
 * Ensure each item is defined, at least as an empty string.
 */
import React from "react"
import styled, { keyframes } from "styled-components"
import { Breakpoints } from "./src/util/breakpoints"
import useSiteMetadata from "./src/hooks/use-site-metadata"
import useUserPreferences from "./src/hooks/use-user-preferences"

import LocalProducts from "./src/components/local-products"
import GoogleMap from "./src/components/google-map"

import SlideShow from "./src/components/slideshow"
import DynamicImage from "./src/components/dynamic-image"
import TabList from "./src/components/tab-list"
import Title from "./src/components/title"

/**********************************************************************
 * Input client website customization below
 **********************************************************************/

const footer = (<>
  <p>
    &copy; 2021 Dos Hermanos. All rights reserved.
    Fontawesome icons are licensed under&nbsp;
    <a href="https://fontawesome.com/license" target="_blank" rel="noreferrer">
      CC-BY 4.0</a>. 
  </p>
</>)

/* Tab info and settings
 * Any tabs you want.
 *
 * For special tabs and components, you'll need to adjust imports in this file
 *
 * Use ProgressiveImage for images over img
 */

const aboutTab = {
  slug: "about",
  name: "About",
  content: (<>
    <h3>Hours</h3>
    <ul>
      <li>Monday &ndash; Saturday: 9 AM &ndash; 9 PM</li>
      <li>Sunday: 9 AM &ndash; 6 PM</li>
    </ul>

    <h3>Location</h3>
    <p>412 W. Michigan Ave, Ypsilanti Mi, 41897</p>
    <GoogleMap src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2953.744773094705!2d-83.6201860842377!3d42.24126535058735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883ca846b30a51a1%3A0x7f7f847479074ec6!2sDos%20Hermanos%20Market!5e0!3m2!1sen!2sus!4v1616439924926!5m2!1sen!2sus" />

    <h3>Contact</h3>
    <ul>
      <li>Phone: 734-487-8839</li>
      <li>
        <a href="https://www.facebook.com/DosHermanosMarket"
          target="_blank"
          rel="noreferrer"
        >
          <i style={{color: "#4267b2"}} className="fab fa-facebook-square fa-lg"></i>&nbsp;
          Message us on Facebook
        </a>
      </li>
    </ul>
    <p>Follow us:</p>
    <div style={{
      display: "grid",
      gridTemplateColumns: "min-content min-content",
      columnGap: "10px",
    }}>
      <a href="https://www.facebook.com/DosHermanosMarket"
        target="_blank"
        rel="noreferrer"
      >
        <i style={{color: "#4267b2"}} className="fab fa-facebook-square fa-3x"></i>
      </a>
      <a href="https://www.instagram.com/explore/locations/238967879/dos-hermanos-market-ypsi/"
        target="_blank"
        rel="noreferrer"
      >
        <i style={{color: "#c13584"}} className="fab fa-instagram fa-3x"></i>
      </a>
    </div>

  </>),
}

const takeoutTab = {
  slug: "takeout",
  name: "Takeout & Deli",
  content: (<>
    <h2>Takeout & Deli</h2>
    <p>
      Come and enjoy our mouth-watering takeout and deli menu.
      (Takeout only.)
    </p>
    <LocalProducts filter={product => product.keywords.includes("takeout")} />
  </>),
  
}

const meatTab = {
  slug: "meat",
  name: "Meat Market",
  content: (<>
    <h2>Meat Market</h2>
    <p>
      Check out our selection of fresh meats.
    </p>
    <LocalProducts filter={product => product.keywords.includes("butcher")} />
  </>),
}

const produceTab = {
  slug: "produce",
  name: "Produce",
  content: (<>
    <h2>Produce</h2>
    <p>
      Fresh fruits and vegetables for your table.
      We carry the usual items:
      onions, tomatoes, etc.
      We also have many delicacies from South and Central America:
    </p>
    <LocalProducts filter={product => product.keywords.includes("produce")} />
  </>),
  
}

const groceryTab = {
  slug: "grocery",
  name: "Grocery",
  content: (<>
    <h2>Grocery</h2>
    <p>
      We have a great selection of groceries from Central and South America.
    </p>
    <LocalProducts filter={product => product.keywords.includes("grocery")} />
  </>),
  
}

const tabs = [
  aboutTab,
  takeoutTab,
  meatTab,
  produceTab,
  groceryTab,
]

/*******************************************************************************
 *
 * Business Splash
 *
 * Defined here to handle a lot of customization.
 * Consider relocating to components once we have themes.
 *
 ******************************************************************************/

const Separator = styled.hr`
  border-top: 1px solid lightgray;
`

// Need to move the id marker up so navigation is visible
// Use only if bottom tab list present
const TabContentPlaceholder = styled.div`
  position: relative;
  top: -3em;
`
const StyledBusinessIdentifiers = styled.div`
  width: 100%;
  display: inline-grid;
  box-sizing: border-box;
  grid-template-columns: ${props => props.shouldUseIconLogo ?
    'min-content auto' :
    'auto'
  };
  column-gap: 15px;
  justify-content: start;
  align-items: center;
  grid-template-areas: ${props => props.shouldUseIconLogo ?
    '"icon title" "description description"' :
    '"title" "description"'
  };

  @media ${Breakpoints.smallOrLarger} {
    padding: 15px;
    padding-top: 0;
    row-gap: 7px;
    grid-template-columns: min-content auto min-content;
    grid-template-areas:
      "icon        title"
      "icon        description";
  }
`

const TitleContainer = styled.div`
  min-width: 60vw;
  grid-area: title;
`

//const IconContainer = styled.div`
//  grid-area: icon;
//  height: 70px;
//  width: 70px;
//
//  @media ${Breakpoints.smallOrLarger} {
//    height: 120px;
//    width: 120px;
//    padding-right: 15px;
//    margin-bottom: 8px;
//  }
//`

const SlideshowContainer = styled.div`
  background-color: var(--backgroundColor);
  @media ${Breakpoints.smallOrLarger} {
    margin: auto;
    max-width: 90%;
  }
  @media ${Breakpoints.largeOrLarger} {
    max-width: 80%;
  }
`

const slideLeft = keyframes`
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
`

const DescriptionContainer = styled.p`
  grid-area: description;
  font-style: italic;
  font-size: larger;
  animation: ${slideLeft} 1s ease-out;

  @media ${Breakpoints.smallOrLarger} {
    margin: 0;
    vertical-align: middle;
  }
`

function BusinessIdentifiers(props) {
  const siteMetadata = useSiteMetadata()
  return (
    <StyledBusinessIdentifiers
      shouldUseIconLogo={true}
      data-qa={props["data-qa"] || "BusinessIdentifiers"}
    >

      {/*
      <IconContainer data-qa={"BusinessIcon"}>
        <DynamicImage
          src="icon.png"
          alt="Business icon"
          data-qa="BusinessIcon"
          shouldShowBackground={false}
        />
      </IconContainer>
      */}

      <TitleContainer>
        <h1>
          <DynamicImage
            src="banner_logo.png"
            alt={siteMetadata.title}
            data-qa="BusinessBanner"
            shouldShowBackground={false}
          />
        </h1>
        {/*
        <Title
          text={siteMetadata.title}
          data-qa={"BusinessTitle"}
        />
        */}
      </TitleContainer>

      <DescriptionContainer data-qa={"BusinessDescription"}>
        {siteMetadata.description}
      </DescriptionContainer>

    </StyledBusinessIdentifiers>

  )
}

function TabPageSplash(props) {
  const userPreferences = useUserPreferences()

  return (<>
    {/*
    <div role={"navigation"}>
      <TabList
        activeTab={props.activeTab}
        tabs={tabs}
      />
    </div>
    */}

    <BusinessIdentifiers />
    {/*
    <SlideshowContainer>
      <SlideShow
        images={userPreferences.splashImages}
        data-qa={"SplashImages"}
      />
    </SlideshowContainer>
    */}


    {/* Ensure bottom tab list is visible when switching tabs
        Only use with bottom tab list
    */}
    <TabContentPlaceholder id="TabContent" />

    <div role={"navigation"}>
      <TabList
        activeTab={props.activeTab}
        tabs={tabs}
      />
    </div>

    <Separator />
  </>)
}

export default function getUserContent() {
  return {
    footer,
    tabs,
    BusinessIdentifiers,
    TabPageSplash,
  }
}
