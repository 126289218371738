/* GlobalStyle
 *
 * props:
 *
 *   theme (str): TODO
 */

import React from "react"
import { createGlobalStyle } from "styled-components"
import { Breakpoints } from "../util/breakpoints"
import useSiteStyles from "../hooks/use-site-styles"

const GlobalStyle = createGlobalStyle`
  body {
    --backgroundColor: ${props => props.color("background")};
    --themedBackgroundColor: ${props => props.color("themedBackground")};
    --imageBackgroundColor: ${props => props.color("imageBackground")};
    --darkBackgroundColor: ${props => props.color("darkBackground")};
    --errorTextColor: ${props => props.color("errorText")};
    --accentColor: ${props => props.color("accent")};
    --disabledAccentColor: ${props => props.color("disabledAccent")};
    --linkColor: ${props => props.color("link")};
    --darkLinkColor: ${props => props.color("darkLink")};
    --textColor: ${props => props.color("text")};
    --lightTextColor: ${props => props.color("lightText")};
  
    font-family: Sans-Serif;
    max-width: 80em;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--textColor);
    background-color: var(--backgroundColor);

    @media ${Breakpoints.smallOrLarger} {
      padding-left: 20px;
      padding-right: 20px;
      line-height: 150%;
    }
  }
  
  h1 {
    @media ${Breakpoints.smallOrLarger} {
      font-size: xx-large;
    }
  }

  h2 {
    font-size: x-large;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
  
  h3 {
    font-size: large;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: normal;

    a {
      :link {
        text-decoration: none;
      }
      :visited {
        text-decoration: none;
      }
      :hover {
        text-decoration: underline;
      }
      :active {
        text-decoration: underline;
      }
    }
  }

  a {
    color: var(--linkColor);
  }

  h4 {
    font-size: large;
    font-style: italic;
    letter-spacing: 0.05em;
    font-weight: normal;
    color: var(--lightTextColor);
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }
`

export default function AppliedGlobalStyle(props) {
  const siteStyles = useSiteStyles()
  return (
   <React.Fragment>
     <GlobalStyle
       theme={props.theme}
       color={colorName => siteStyles.colors[colorName].toString()}
     />
     {props.children}
   </React.Fragment>
  )
}
